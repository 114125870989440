@font-face {
  font-family: "NexaBlack";
  src: url("../assets/NexaBlack.otf") format("truetype");
}

@font-face {
  font-family: "NexaBold";
  src: url("../assets/NexaBold.otf") format("truetype");
}

@font-face {
  font-family: "Nexabook";
  src: url("../assets/NexaBook.otf") format("truetype");
}

@font-face {
  font-family: "NexaBlackItalic";
  src: url("../assets/NexaBlackItalic.otf") format("truetype");
}

@font-face {
  font-family: "Nexaheavy";
  src: url("../assets/NexaHeavy.otf") format("truetype");
}

@font-face {
  font-family: "NexaLightItalic";
  src: url("../assets/NexaLightItalic.otf") format("truetype");
}

@font-face {
  font-family: "NexaRegular";
  src: url("../assets/NexaRegular.otf") format("truetype");
}

@font-face {
  font-family: "NexaThin";
  src: url("../assets/NexaThin.otf") format("truetype");
}

@font-face {
  font-family: "NexaThinItalic";
  src: url("../assets/NexaThinItalic.otf") format("truetype");
}

@font-face {
  font-family: "NexaXBold";
  src: url("../assets/NexaXBold.otf") format("truetype");
}

* {
  font-family: "Poppins", sans-serif;
}

.signLeftPartBox {
  width: 100%;
  position: relative;
}

.ant-row.css-dev-only-do-not-override-mxhywb {
  width: 100%;
  height: 903px;
}

.main-logo-div:hover {
  cursor: pointer !important;
}

.main-logo-div-container:hover {
  cursor: pointer;
}


.signRightPartBox {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.password_match_icon {
  margin-bottom: 6px !important;
  height: 18px !important;
}

.rightPartContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logobox {
  height: 168px;
  width: 213px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #FFF !important;
}

.signUpTextBox {
  text-align: center;
  width: 100%;
}

.signUpText {
  color: #47a277;
  font-size: 28px;
  font-weight: 700;
}

.role-chart-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50px;
}

.role-chart {
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #9dcfbb;
}

.role-chart-active {
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #47a277;
}

.ant-select.ant-select-in-form-item {
  background-color: #FFF !important;
}

.roleText {
  color: #9dcfbb;
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
}

.roleText-active {
  color: #47a277;
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
}

.flow-chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flow-line {
  width: 50px;
  background: #9dcfbb;
  height: 1px;
  margin: 0 10px 0 10px;
}

.flow_line_select {
  width: 50px;
  background: #47a277;
  height: 1px;
  margin: 0 10px 0 10px;
}

.flow-label-line {
  width: 50px;
  height: 1px;
  margin: 0 10px 0 10px;
}

.flow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.userContainer {
  display: flex;
  justify-content: center;
  height: 105px;
}

.shoperBox {
  width: 75px;
  margin-right: 24px;
  height: 70px;
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #6c75
}

.shoper-img {
  margin: 0;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shoper-img-text {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #47a277;
  margin-top: 24px;
}

.continue-btn {
  width: 291px !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 0;
  background-color: #47a277;
  color: white;
  font-size: 17px;
  cursor: pointer;
  letter-spacing: 1px;
}

.btn-group-container {
  width: 100%;
}

.signUpWith {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  width: 170px;
  margin-top: 10px;
}

.continue-btn-box {
  text-align: center;
  margin-top: 5px;
}

.google-btn {
  width: 268px;
  height: 50px;
  border-radius: 10px;
  color: black;
  font-size: 16px;
  border: 1px solid #9dcfbb;
  cursor: pointer;
  position: relative;
}

.social_string {
  line-height: 1px !important;
}

.google_btn_main {
  width: 291px;
}

.google_btn_width {
  width: 291px !important;
}

.fb-btn-grup {
  margin-top: 15px;
}

.bar-line {
  width: 284px;
  height: 1px;
  background-color: black;
}

.about-title-container .custom-collapse p {
  font-size: 16px !important;
}

.bar-line-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.already-text {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #868686;
}

.login-text {
  color: #47a277;
  font-weight: 500;
  padding-bottom: 0px;
  cursor: pointer;
  text-decoration: underline;
}

.sinup_log {
  width: 100%;
  height: 100vh;
}

.main_flow_line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.main_inner_flow {
  width: 49px !important;
  background-color: #656565 !important;
}

.shopper_icon {
  width: 42px;
  cursor: pointer;
  margin-top: 23px;
}

.shoperBox-transform {
  width: 75px;
  margin-right: 24px;
  height: 70px;
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #6c75;
  transform: scale(1.2);
  background-color: #4fa97f;
}

.google_icon {
  width: 20px;
  height: 21px;
  position: absolute;
  left: 25px;
  top: 14px;
}

.facebook_icon {
  width: 14px;
}


.sign-indv-form {
  width: 300px;
}

.ant-form-item.css-dev-only-do-not-override-mxhywb {
  margin-bottom: 0;
}

.form-accnt-box {
  height: 40px !important;
  border-radius: 15px !important;
}

.form-accnt-box-border {
  height: 40px !important;
  border-radius: 15px !important;
  border-color: #47a277 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input:hover {
  border-color: #d9d9d9 !important;
  border-inline-end-width: 1px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
:where(.css-dev-only-do-not-override-mxhywb).ant-input-focused {
  border-color: #47a277 !important;
  box-shadow: none !important;
  border-inline-end-width: 1px;
  outline: 0;
}

.form-text {
  margin-bottom: 0px;
  padding-left: 15px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:hover {
  border-color: #d9d9d9 !important;
  border-inline-end-width: 1px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:focus,
:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper-focused {
  border-color: #47a277 !important;
  box-shadow: none !important;
  border-inline-end-width: 1px;
  outline: 0;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-btn-default:not(:disabled):hover {
  color: white !important;
  border-color: #47a277 !important;
}

.form-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.form_Done {
  margin-top: 10px !important;
}

.star-color {
  color: #47a277;
  font-size: 16px;
}

.new_input {
  display: none;
}

.ant-btn {
  font-size: 20px !important;
  height: 50px !important;
}

.category_margin {
  margin-top: 10px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #4fa97f;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.form_continue_btn {
  height: 50px !important;
  width: 299px !important;
  border-color: #9dcfbb !important;
  background: #9dcfbb !important;
  color: #FFF !important;
  border-radius: 10px !important;
  cursor: not-allowed;
}

.form_continue_btn_user {
  background: #9dcfbb !important;
}

.vendor_form_color {
  color: #4fa97f !important;
}

.form_continue_btn_enabled {
  height: 50px !important;
  border-radius: 10px !important;
  width: 291px !important;
  background: #4fa97f !important;
  color: #FFF !important;
}

.form_continue_btn_enabled_user {
  background: #4fa97f !important;
  transition: box-shadow 250ms;
}

.form_continue_btn_enabled_user:hover{
box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.ant-input:hover {
  border-color: #47a277 !important;
  border-right-width: 1px;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgb(82 196 26 / 20%);
  background-color: #9bddbf !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #47a277 !important;
  border-right-width: 1px;
}

.signup_datepicker {
  width: 100%;
  height: 40px !important;
  border-radius: 15px !important;
  border: 1px solid #d9d9d9;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgb(82 196 26 / 20%) !important;
  background-color: #9bddbf !important;
}

.form_continue_btn_enabled:hover {
  color: #FFF !important;
}

.contact_select_input {
  height: 40px !important;
  border-radius: 15px !important;
  border: 1px solid #d9d9d9 !important;
}

.check_terms_condition {
  display: flex;
}

.terms_checkbox {
  width: 36px;
}

.terms_checkbox .ant-checkbox-wrapper {
  border-radius: 8px;
}

.terms_checkbox .ant-checkbox-input {
  border-radius: 8px;
}

.terms_checkbox .ant-checkbox-inner {
  border-radius: 8px;
  font-size: 16px;
  transform: scale(1.2);
}

.terms_condition_span {
  color: #4fa97f !important;
  border-bottom: 1px solid #47a277;
  cursor: pointer;
}

.bar_line_box_terms {
  display: flex;
}

.terms_text {
  font-size: 14px !important;
  margin-top: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4fa97f !important;
  border-color: #4fa97f !important;
  border-radius: 4px;
}

.ant-checkbox-inner::after {
  width: 8.714px !important;
  height: 13.143px !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4fa97f !important;
  border-right-width: 1px;
}

.main_title {
  position: absolute;
  top: 4%;
  left: 9%;
  font-size: 63px;
  color: #FFF;
  font-weight: 400;
  letter-spacing: 2px;
}

.new_class_vendor {
  height: 50px !important;
  border-radius: 10px !important;
  width: 291px !important;
  border-color: #9bddbf !important;
  background: #9bddbf !important;
  cursor: not-allowed;
}

.ant-checkbox-inner :hover {
  color: #4fa97f !important;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0px;
  display: block;
  width: 25px !important;
  height: 25px !important;
  font-size: 39px;
}

.pdf_file_name {
  margin-top: 10px;
  width: 40%;
  border-bottom: 1px solid #4fa97f;
  color: #4fa97f;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new_enabled {
  background-color: #4fa97f !important;
  color: #FFF !important;
}

.form_new_class {
  background-color: #9bddbf !important;
  color: #47a277 !important;
}

.cancel_icon {
  color: red !important;
}

.cancel_pdf {
  display: flex;
  align-items: center;
}

.terms_margin {
  margin-top: 10px !important;
}

.new_upload_icon {
  font-size: 43px;
  padding-right: 18px;
  margin-left: 45px;
}

.upload_evidence_data {
  text-align: center;
  padding: 0px 29px
}

.new_class_upload {
  color: #fff !important;
  margin-bottom: 10px;
}

.learn-more-parent {
  background-color: var(--background-color);
  height: fit-content !important;
}

.resp-learn-more-top {
  display: none;
  align-items: center;
  justify-content: space-around;
  width: 90%;
}

.resp-learn-more-top:hover {
  cursor: pointer !important;
}

.shopper_icon_learnmore {
  width: 55px;
  cursor: pointer;
}

.learn-more-top-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-bottom: 20px !important;
}

.learn-more-txt-mobile-text {
  display: none;
  font-size: 18px;
  text-align: center;
  color: black;
}

.toggle-tab-container {
  display: flex;
}

.upper-tab-text {
  color: #47a277;
  text-decoration: underline;
}

.learn-more-parent * {
  font-family: "Poppins", sans-serif;
}

.l-about-us-banner {
  position: relative;
  display: flex;
  justify-content: center;
}

.l-about-us-title-txt {
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.about-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 1250px;
}

.custom-collapse {
  border-bottom: 1px solid #e8e8e8;
}

.custom-collapse .ant-collapse-borderless {
  width: 1250px;
  background-color: transparent !important;
}

.custom-panel-header {
  font-weight: bold;
  font-size: 20px;
}

.join-community-banner {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 40px !important;
  background: linear-gradient(to right, #fff, #a1e4c5);
  width: 1250px;
  border-radius: 8px;
  margin-bottom: 40px !important;
}

.shopper-community-banner {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin-top: 40px !important;
  background: linear-gradient(to right, #a1e4c5, #fff);
  width: 1250px;
  border-radius: 8px;
  margin-bottom: 40px !important;
  height: 300px;
}

.join-community-text {
  font-weight: bold;
  font-size: 20px;
  max-width: 78%;
  text-align: center;
  padding-top: 25px;
}

.sign-up-notify {
  max-width: 60% !important;
  padding: 10px !important;
}

.shopper-community-desc-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
}

.shopper-community-text {
  font-size: 20px;
  max-width: 70%;
}

.few-clicks {
  max-width: 65% !important;
}

.custom-icon-about-us img {
  width: 20px;
  height: 20px;
  margin-inline: 10px;
}

.custom-icon-about-us {
  font-size: 16px;
}

.tired-banner-container {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  bottom: 0px;
}

.tired-p-text {
  font-size: 20px;
  max-width: 80%;
  opacity: 0.8;
}

#custom-button {
  background: linear-gradient(to right, #a1e4c5, #47a277) !important;
  transition: 0.3s ease-in;
  padding: 10px 20px;
  color: white;
  margin-bottom: 20px;
  border-radius: 10px !important;
  border: none !important;
  height: 40px !important;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learn-more-txt {
  position: absolute;
  bottom: -1px;
  color: white;
  font-size: 20px;
  left: 17%;
}


#custom-button :hover,
#custom-button :focus {
  background-color: #4fa97f !important;
  transition: 0.3s ease-in;
  padding: 10px 20px;
  border: none !important;
  color: white !important;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img-abt {
  height: 200px;
  width: 200px;
}

.logo-container-abt {
  position: absolute;
  right: 18%;
  top: 25px;
}


.wlcm-lochi-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  max-width: 45%;
  top: 50%;
  left: 33%;
}

.wlcm-lochi-h2 {
  color: white;
}

.mission-text {
  font-size: 18px;
  opacity: 0.8;
}

.founder-div {
  position: absolute;
  color: white;
  bottom: 65px;
  font-size: 18px;
  text-align: right;
  right: 20%;
  opacity: 0.8;
}

.small-business-txt {
  margin-left: -30px;
}

.what-is-lochi-cont {
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  width: 1250px;
  align-items: center;
}

.beginText {
  color: #8d8d8d;
}

.what-is-lochi-txt {
  margin-inline: 38px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.welcom-lochi-mart-cont {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  width: 1250px;
  margin: auto;
}

.business-description-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-main-lochi {
  height: 200px;
}

.welcom-lochi-mart-cont h1,
.welcom-lochi-mart-cont h2 {
  color: white;
  text-align: center;
}

.wlcm-lochi-que-cont {
  display: flex;
  margin: auto;
}

.lochi-benefits-sub-points {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.lochi-benefits-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.lochi-benefits-main-container {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 90%;
}

.wlcm-lochi-que-cont p {
  margin-inline: 25px;
  font-size: 18px;
  text-align: center;
  opacity: 0.8;
}

.upper-box-transform {
  width: 55px;
  margin-right: 24px;
  height: 55px;
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #6c75;
  transform: scale(1.2);
  background-color: #4fa97f;
}

.ant-collapse-header {
  color: black !important;
  font-weight: 600 !important;
}

.lochi-learn-more-link {
  text-decoration: underline !important;
  color: #47a277 !important;
}


@media (max-width: 1250px) {

  .learn-more-txt-mobile-text {
    display: block;
  }

  .learn-more-txt {
    display: none;
  }

  .what-is-lochi-cont {
    flex-direction: column;
    width: 90%;
  }

  .about-title-container,
  .join-community-banner,
  .custom-collapse .ant-collapse-borderless,
  .welcom-lochi-mart-cont {
    width: 90%;
  }

  .about-us-banner-imgs {
    width: 90%;
  }

  .logo-img-abt {
    height: 150px;
    width: 150px;
  }

  .logo-container-abt {
    position: absolute;
    right: 70px;
    top: 10px;
  }

  .wlcm-lochi-container {
    top: 30%;
  }

  .shopper-community-banner {
    width: 90%;
    flex-direction: column;
    height: fit-content;
  }

  .shopper-community-text {
    font-size: 18px;
  }

  .shopper-community-desc-cont {
    margin-top: 20px;
  }

  .tired-p-text {
    font-size: 18px;
  }

  .logo-main-lochi {
    height: 150px !important;
  }

  .img-small-businesses-banner {
    width: 90%;
  }

  .wlcm-lochi-que-cont,
  .need-to-join-lochi-container {
    display: none;
  }

  .lochi-benefits-main-container {
    display: flex;
  }

  .platform-main-text {
    font-size: 22px !important;
    text-align: center;
  }

  .business-description-container {
    margin: 10px;
    width: 90%;
  }

}

@media (max-width: 1050px) {
  .founder-div {
    right: 90px;
    bottom: 10px;
  }

}

@media (max-width: 900px) {

  .about-title-container,
  .custom-collapse .ant-collapse-borderless {
    align-items: flex-start;
  }

  .resp-learn-more-top {
    display: flex;
  }

  .main-logo-div {
    display: none;
  }



  .platform-main-text {
    font-size: 18px !important;
    text-align: center;
  }

  .tired-p-text {
    font-size: 16px;
  }

  .logo-main-lochi {
    height: 150px !important;
  }

  .img-small-businesses-banner {
    width: 90%;
    height: 250px;
  }

  .l-about-us-title-txt {
    font-size: 25px
  }

  .join-community-text {
    font-size: 18px;
  }

  .about-us-banner-imgs {
    height: 225px;
  }

  .logo-container-abt {
    position: absolute;
    right: 35px;
    top: 10px;
  }

  .logo-img-abt {
    height: 90px;
    width: 90px;
  }

  .wlcm-lochi-container {
    top: 40px;
  }

  .wlcm-lochi-h2 {
    color: white;
    font-size: 20px;
  }

  .mission-text {
    font-size: 16px;
  }

  .founder-div {
    font-size: 16px;
    bottom: 10px;
    right: 60px;
  }

  .custom-collapse {
    width: inherit;
  }

  .business-description-container {
    margin: 10px;
    width: 90%;
  }

  .lochi-benefits-sub-points p {
    padding: 10px;
    font-size: 16px;
  }

  .business-images {
    width: 150px;
    height: 250px;
  }

  .what-is-lochi-txt {
    font-size: 16px;
  }

}

@media (max-width: 580px) {
  .logo-img-abt {
    height: 60px;
    width: 60px;
  }

  .business-images {
    width: 100px;
    height: 200px;
  }

  .what-is-lochi-txt {
    font-size: 14px;
  }

  .platform-main-text {
    font-size: 16px !important;
    text-align: center;
  }

  .wlcm-lochi-container {
    top: 20px;
  }

  .wlcm-lochi-h2 {
    color: white;
    font-size: 16px;
  }

  .mission-text {
    font-size: 12px;
  }

  .lochi-benefits-sub-points p {
    padding: 10px;
    font-size: 15px;
  }

  .founder-div {
    font-size: 12px;
    bottom: 3px;
  }

}


.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
  border-radius: 10px;
  height: 100%;
}

sup {
  top: -4px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #47a277 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

@media(max-width:375px) {
  .iphone_se_res {
    display: flex !important;
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .google_btn_width {
    width: 67px !important;
  }

  .upper-tab-text {
    font-size: 12px;
  }

  .iphone_se_res button {
    text-indent: -9999px;
  }
}

@media(max-width:414px) {
  .iphone_se_res {
    display: flex !important;
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .google_btn_width {
    width: 67px !important;
  }

  .iphone_se_res button {
    text-indent: -9999px;
  }
}